* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}

body {
  position: relative;
  width: 100%;
  height: 100vh;
  background: rgb(14, 24, 24);
  background: linear-gradient(90deg, rgba(14, 24, 24, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 1) 100%);
}

#root {
  height: 100%;
}

nav {
  position: fixed;
  width: 100%;
  padding: 20px 40px;
  display: flex;
  z-index: 12;
  background: rgb(14, 24, 24);
  background: linear-gradient(90deg, rgba(14, 24, 24, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 1) 100%);
}

a {
  display: block;
  text-decoration: none;
}

nav ul {
  margin-left: auto;
  display: flex;
}

nav ul li {
  list-style-type: none;
  padding: 10px 20px;
}

nav ul li a {
  color: #fff;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  position: relative;
  transition: all 0.2s;
}

nav ul li a:hover,
nav ul li a.active {
  color: #7e1361;
  transition: all 0.2s;
}

nav ul li a::before {
  content: ' ';
  display: block;
  width: 0px;
  height: 3px;
  position: absolute;
  bottom: -4px;
  left: 0px;
  background: #fff;
  transition: all 0.2s;
}

nav ul li a:hover:before,
nav ul li a.active:before {
  width: 14px;
  background: #7e1361;
}

.container {
  width: 100%;
  padding: 125px 40px 40px 40px;
}

.aesthatiks-name {
  position: relative;
  margin-bottom: 40px;
  z-index: 3;
}

.aesthatiks-tagline {
  position: relative;
  margin-bottom: 40px;
  z-index: 5;
}

.aesthatiks-tagline ul {
  padding-top: 24px;
  padding-bottom: 24px;
}

.aesthatiks-tagline ul li {
  position: relative;
  list-style: none;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  line-height: 32px;
  padding-left: 32px;
}

.aesthatiks-tagline ul li:before {
  content: ' ';
  display: block;
  width: 8px;
  height: 8px;
  background: #7e1361;
  position: absolute;
  left: 0;
  top: 12px;
  border-radius: 8px;
}

.aesthatiks-tagline ul li:hover:before {
  width: 20px;
  transition: all 0.1s ease-in-out;
}

.aesthatiks-tagline .tag {
  font-weight: bold;
  color: #7e1361;
}

.aesthatiks-image {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 4;
  height: 70%;
  max-height: 690px;
  overflow: hidden;
}

.aesthatiks-image.faded {
  opacity: 0.2;
}

.btn-download {
  display: inline-block;
  padding: 12px 32px;
  border-radius: 100px;
  background: #7e1361;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.btn-download:hover {
  background: #a1207f;
  transition: all 0.3s ease-in-out;
}

.project-block {
  width: 100%;
  height: 680px;
  background: #fff;
  display: flex;
  align-items: center;
  margin-bottom: 80px;
  border-bottom: 10px solid transparent;
  transition: all 0.3s ease-in-out;
}

.project-block:hover {
  border-bottom: 10px solid #7e1361;
  transition: all 0.3s ease-in-out;
}

.project-name {
  padding: 40px;
  width: 30%;
}

.project-name-main {
  color: #333;
  font-size: 32px;
  font-weight: bold;
}

.project-name-sub {
  color: #666;
  font-size: 24px;
  font-weight: normal;
}

.project-image-block {
  display: flex;
  position: relative;
  width: 70%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.project-image-blur {
  position: absolute;
  height: 100%;
  width: 100%;
  background-size: cover;
  filter: blur(50px);
  -webkit-filter: blur(50px);
  z-index: 3;
  opacity: 0.7;
}

.project-image-blur:before {
  content: ' ';
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
}

.project-image-blur img {
  width: 100%;
  height: 100%;
}

.project-image {
  position: relative;
  width: 90%;
  height: 70%;
  background-size: contain;
  z-index: 4;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.project-image img {
  width: 100%;
}

.project-bg {
  width: 100%;
  background: #fff;
  padding: 80px 160px 0px 160px;
  margin: 0 auto;
}

.project-bg h1 {
  font-size: 32px;
  color: #333;
}

.project-bg h2 {
  font-size: 28px;
  color: #484848;
  font-weight: normal;
  padding-bottom: 80px;
}

.project-bg h3 {
  font-size: 32px;
  color: #333;
  padding-bottom: 16px;
}

.project-bg h4 {
  font-size: 24px;
  font-weight: normal;
  color: #484848;
  padding-bottom: 16px;
}

.project-bg p {
  font-size: 14px;
  line-height: 24px;
  color: #484848;
  padding-bottom: 12px;
}

.project-bg img {
  width: 100%;
}

.project-bg .image-row {
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: space-between;
}

.project-bg .image-row img {
  width: auto;
  height: 100%;
}

.divider {
  width: 100%;
  padding: 120px 160px;
}

.divider:before {
  content: ' ';
  display: block;
  width: 100%;
  height: 1px;
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(204, 204, 204, 1) 50%, rgba(255, 255, 255, 1) 100%);
}

.col-2 {
  width: 100%;
  display: flex;
}

.col-2 div {
  width: 50%;
  padding-right: 80px;
}

.col-right {
  justify-content: flex-end;
}

.expanded-box {
  width: calc(100% + 320px);
  margin-left: -160px;
  margin-top: 80px;
  padding: 80px 160px;
}

.dark {
  background: #efefef;
}

.scroll-btn {
  display: block;
  background: #7e1361;
  height: 40px;
  width: 40px;
  padding: 10px 14px;
  border-radius: 100px;
}

.scroll-btn:before {
  content: ' ';
  display: block;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid transparent;
}

@media screen and (max-width: 1080px) {
  .project-bg .image-row {
    height: 300px;
  }

  .col-2 {
    flex-direction: column;
  }

  .col-2 div {
    width: 100%;
    padding-right: 0px;
  }
}

@media screen and (max-width: 640px) {
  .container {
    padding: 125px 20px 120px;
  }

  nav {
    padding: 20px;
    justify-content: center;
  }

  .aesthatiks-name {
    overflow: hidden;
  }

  .aesthatiks-tagline svg {
    width: 100%;
  }

  .aesthatiks-tagline svg.left {
    width: auto;
  }

  .aesthatiks-image {
    height: 80%;
    width: 60%;
    overflow: hidden;
  }

  nav ul {
    position: fixed;
    z-index: 99;
    left: 0;
    bottom: 0px;
    width: 100%;
    padding: 10px 10px;
    flex-direction: row;
    background: #000;
  }

  nav ul.is-active:after {
    content: ' ';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    z-index: -10;
  }

  nav ul li {
    width: 100%;
    text-align: center;
    padding: 0;
    margin: 12px 24px;
  }

  nav ul li a {
    font-size: 12px;
  }

  nav ul li a::before {
    height: 6px;
    bottom: -22px;
  }

  nav ul li a:hover:before,
  nav ul li a.active:before {
    width: 100%;
  }

  nav .home {
    position: relative;
    z-index: 100;
  }

  .hamburger {
    display: inline-block;
    margin-left: auto;
    position: relative;
    z-index: 100;
  }

  .aesthatiks-tagline h1,
  .aesthatiks-tagline p {
    width: 100%;
  }

  .project-bg {
    width: 100%;
    background: #fff;
    padding: 80px 40px 0px;
  }

  .divider {
    padding: 40px 60px;
  }

  .expanded-box {
    width: calc(100% + 80px);
    margin-left: -40px;
    margin-top: 80px;
    padding: 40px;
  }

  .project-block {
    flex-direction: column;
    height: 500px;
  }

  .project-name,
  .project-image-block {
    width: 100%;
  }

  .project-bg .image-row {
    height: 180px;
  }

  .scroll-position > div {
    right: 10px !important;
    bottom: 20px !important;
  }
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mt-40 {
  margin-top: 40px;
}

.img-border {
  border: 1px solid #efefef;
}

.fade-in-1 {
  animation: fade-in-1 0.7s ease-in-out;
}

.fade-in-2 {
  animation: fade-in-2 0.5s ease-in-out;
}

.fade-in-3 {
  animation: fade-in-3 0.5s ease-in-out;
}

@keyframes fade-in-1 {
  0% {
    opacity: 0;
    transform: translatex(-120px);
  }
  100% {
    opacity: 1;
    transform: translatex(0px);
  }
}

@keyframes fade-in-2 {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0;
    transform: translatex(-120px);
  }
  100% {
    opacity: 1;
    transform: translatex(0px);
  }
}

@keyframes fade-in-3 {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0;
    transform: translatex(120px);
  }
  100% {
    opacity: 1;
    transform: translatex(0px);
  }
}
